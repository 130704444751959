import { FC, memo, useEffect, useRef, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import Text from 'src/components/UI/Text/Text';
import { productDescriptionRules } from './ProductDescription.style';
import { Icon } from 'src/components/Icon/Icon';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';

interface IProps {
  text: string;
}

export const ProductDescription: FC<IProps> = memo(function ProductDescription(props) {
  const [open, setOpen] = useState(false);
  const descriptionRef = useRef<HTMLParagraphElement>(null);
  const [descriptionHeight, setDescriptionHeight] = useState(0);
  const { text } = props;

  const { t } = useTranslation()

  useEffect(() => {
    if (descriptionRef.current) {
      setDescriptionHeight(descriptionRef.current.clientHeight);
    }
  }, [descriptionRef, text]);

  const { css } = useStyle(productDescriptionRules, {
    open,
  });

  const toggleOpenState = () => {
    setOpen(!open);
  };
  return (
    <div className={css.wrapper}>
      <Text text={t('productDescription.description')} mod="title" fontSize="" extend={css.title} />
      <Text
        mod="text"
        fontSize="14px"
        lineHeight="20px"
        text={text}
        extend={css.text}
        ref={descriptionRef}
      />
      {descriptionHeight >= 60 && (
        <button onClick={toggleOpenState} className={css.button}>
          {open ? 'Скрыть' : 'Смотреть полностью'}
          <Icon name="chevron-right" properties={{ className: css.icon }} />
        </button>
      )}
    </div>
  );
});
