import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface IDropListKeys {
  droplist: {};
  droplistHeader: {};
  droplistTitle: {};
  dropCount: {};
  icon: {};
  dropWrp: {};
  dropRow: {};
  span: {};
  dropInner: {};
}

interface IDropListRuleProps {
  open: boolean;
}

export type IdroplistRules = IRuleFn<IDropListKeys, IDropListRuleProps>;

const droplistRuleFn: IdroplistRules = props => {
  const { colorBlack } = props.theme;
  const { open } = props;

  return {
    droplist: {
      padding: '0 16px 0 16px',
      borderBottom: '1px solid rgba(28, 25, 22, 0.1)',
    },
    droplistHeader: {
      display: 'flex',
      gap: '8px',
      padding: '16px 0',
    },
    droplistTitle: {
      maxWidth: '70%',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      fontSize: '20px',
      lineHeight: '26px',
      fontWeight: '800',
    },
    dropCount: {
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: '400',
      color: colorBlack,
      opacity: '0.5',
    },
    icon: {
      flexShrink: 0,
      marginLeft: 'auto',
      transition: 'transform .25s',
      transform: open ? 'rotateX(180deg)' : '',
    },
    dropWrp: {
      transition: 'height .25s',
      overflow: 'hidden',
    },
    dropInner: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      paddingBottom: '40px',
    },
    dropRow: {
      fontSize: '16px',
      lineHeight: '22px',
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      color: colorBlack,
    },
    span: {
      opacity: '0.4',
    },
  };
};

export const droplistRules = createCachedStyle(droplistRuleFn);
