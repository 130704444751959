import { memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { useTheme } from 'src/utils/theme/useTheme';
import { Button } from '../Button/Button';
import { buttonBasketRules } from './buttonBasket.style';
import { useAppSelector } from 'src/hooks/redux';
import { cartState } from '../../../redux/slices/cartSlice';

interface IProps {
  href: string;
}

const ButtonBasket = function ButtonBasket(props: IProps) {
  const { href } = props;
  const { css } = useStyle(buttonBasketRules);
  const { theme } = useTheme();
  const { products } = useAppSelector(cartState);
  const productsQuantity = products.reduce((sum, product) => sum + product.count, 0);

  return (
    <Button
      icon="basket"
      propsStyles={{ padding: '16px', background: theme.colorGrey }}
      href={href}
    >
      {productsQuantity > 0 && (
        <div className={css.counter}>
          <span>{productsQuantity}</span>
        </div>
      )}
    </Button>
  );
};

export default memo(ButtonBasket);
