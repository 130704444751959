import { FC, memo, useEffect } from 'react';
// import Container from 'src/components/Container/Container';
import { Layout } from 'src/components/Layout/Layout';
// import { useStyle } from 'src/utils/theme/useStyle';
// import { productPageRules } from './productPage.style';
import Footer from 'src/components/Footer/Footer';
import { Button } from 'src/components/UI/Button/Button';
import { ProductSlider } from 'src/blocks/Product/components/ProductSlider/ProductSlider';
import { ProductMainInfo } from 'src/blocks/Product/components/ProductMainInfo/ProductMainInfo';
import Wrapper from 'src/components/Wrapper/Wrapper';
import { useStyle } from 'src/utils/theme/useStyle';
// import { ProductModificators } from 'src/blocks/Product/components/ProductModificators/ProductModificators';
import Container from 'src/components/Container/Container';
import { ProductDescription } from 'src/blocks/Product/components/ProductDescription/ProductDescription';
import { useTheme } from 'src/utils/theme/useTheme';
import { useScrollToTop } from 'src/hooks/useScrollToTop';
import { productPageRules } from './productPage.style';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { getProductDetail, productState } from '../../redux/slices/productDetailSlice';
import useLoader from 'src/hooks/useLoader';
import { NetworkStatus } from 'src/utils/connect/connectConstant';
import useBackButton from 'src/hooks/useBackButton';
import { useNavigate, useParams } from 'react-router-dom';
import { addProductToCart, cartState, changeCartCount } from '../../redux/slices/cartSlice';
import Selection from 'src/components/Selection/Selection';
import { selectionsProductPage } from '../../redux/slices/selectionsSlice';
import { configState } from 'src/redux/slices/configSlice';
import { DeliveryType } from '@teleport/schemas-protobuf';
import { useTelegram } from 'src/utils/telegramProvider';
import { ModalResult } from 'src/components/ModalResult/ModalResult';
import { useModalState } from 'src/hooks/useModalState';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import Header from '../../components/Header/Header';

export const ProductPage: FC = memo(function ProductPage() {
  const { css } = useStyle(productPageRules);
  const { theme } = useTheme();
  useScrollToTop();
  const { showLoader, hideLoader } = useLoader();
  const BackButton = useBackButton();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { productId } = useParams();
  const { product, networkStatus } = useAppSelector(productState);
  const productPageSelections = useAppSelector(selectionsProductPage);
  const { products } = useAppSelector(cartState);
  const { config } = useAppSelector(configState);
  const productIsInCart = products.filter(product => product.uuid === productId).length > 0;
  const { webApp } = useTelegram();
  const [renderModal, activeModal, openModal, closeModal] = useModalState();

  const { t } = useTranslation()

  function openProductLink() {
    if (product.deliveryAction.case === 'deliveryLink') {
      webApp.openLink(product.deliveryAction.value);
    }
  }

  // Заказать
  function toOrder() {
    openModal();
    console.info('Order');
  }

  // Оформить заказ
  function makeOrder() {
    console.info('Place an order');
    navigate(`/make-single-order/${productId}`);
  }

  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate(-1);
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  useEffect(() => {
    dispatch(getProductDetail(productId));
  }, [dispatch, productId]);

  useEffect(() => {
    if (networkStatus === NetworkStatus.Loading) {
      showLoader();
    }
    if (networkStatus === NetworkStatus.Done) {
      hideLoader();
    }
  }, [networkStatus, hideLoader, showLoader]);

  if (!product) {
    return null;
  }

  const addProductToCartFn = () => {
    if (productIsInCart) {
      dispatch(changeCartCount({ cartItemId: productId, increase: true }));
      return;
    }
    if (product) {
    }
    dispatch(addProductToCart(product));
  };

  return (
    <Layout
      header={<Header />}
      footer={
        <Footer>
          {config.cartEnabled ? (
            <Button
              text={productIsInCart ? t('productPage.addMore') : t('productPage.addToCart')}
              onClick={addProductToCartFn}
              propsStyles={{ width: '100%', height: 62 }}
            />
          ) : config.deliveryType === DeliveryType.DIGITAL ? (
            <Button
              text={t('productPage.checkout')}
              propsStyles={{ width: '100%', height: 62 }}
              onClick={makeOrder}
            />
          ) : config.deliveryType === DeliveryType.PHYSICAL ? (
            <Button
              text={t('productPage.checkout')}
              propsStyles={{ width: '100%', height: 62 }}
              onClick={makeOrder}
            />
          ) : config.deliveryType === DeliveryType.REQUEST ? (
            <Button text={t('productPage.order')} propsStyles={{ width: '100%', height: 62 }} onClick={toOrder} />
          ) : config.deliveryType === DeliveryType.SHOWCASE ? (
            // Добавить prop revertIcon
            <Button
              text={t('productPage.proceedToPurchase')}
              propsStyles={{ width: '100%', height: 62 }}
              icon="arrow-top-right"
              iconAtTheEnd={true}
              onClick={openProductLink}
            />
          ) : null}
        </Footer>
      }
    >
      <div className={css.productInner}>
        <ProductSlider productData={product} />
        <Wrapper extend={css.wrapperInfo} propsStyles={{ paddingBottom: 0 }}>
          <Container>
            <ProductMainInfo productData={product} />
            {/* <ProductModificators /> */}
            <ProductDescription text={product.description} />
          </Container>
          {productPageSelections.length > 0 && (
            <Wrapper
              propsStyles={{
                // TODO fixme
                // borderRadius: '16px 16px 0px 0px',
                backgroundColor: theme.background,
                paddingBottom: '20px',
              }}
              extend={css.wrapperCollections}
            >
              {productPageSelections.map(selection => (
                <Selection selectionData={selection} key={selection.title} location="productPage" />
              ))}
            </Wrapper>
          )}
        </Wrapper>
      </div>
      {renderModal && (
        <ModalResult
          onClose={closeModal}
          active={activeModal}
          success={true}
          text={t('productPage.weWillContactYouShortlyViaTheProvidedContactDetails')}
          title={t('productPage.orderPlaced')}
        />
      )}
    </Layout>
  );
});
