import { Interceptor, ConnectError } from '@connectrpc/connect';

export const statusCodeInterceptor: Interceptor = next => async request => {
  let res;
  try {
    res = await next(request);
  } catch (err) {
    if (err instanceof ConnectError) {
      if (err.code === 16) {
        const portBotUsername = window.localStorage.getItem('portBotUsername');
        location.href = `https://t.me/${portBotUsername}/port`;
      }
    }
  }
  return res;
};
