import { FC, memo } from 'react';
import Text from 'src/components/UI/Text/Text';
import { useStyle } from 'src/utils/theme/useStyle';
import { Price } from 'src/components/Price/Price';
import { productMainInfoRules } from './productMainInfo.style';
import { ClientCatalogProductFull } from '@teleport/schemas-protobuf';
interface IProps {
  productData: ClientCatalogProductFull;
}

export const ProductMainInfo: FC<IProps> = memo(function ProductMainInfo(props) {
  const { css } = useStyle(productMainInfoRules);
  const { productData } = props;
  const { title, price, categories } = productData;

  const rootCategory = categories.find(el => el.type.case === 'rootCategory');

  return (
    <div>
      <div className={css.header}>
        <Text text={title} mod="title" />
        {price.discountEnabled ? (
          <div className={css.priceWrapper}>
            <Price amount={price.amount} hasSymbol={false} mod="large" />
            <Price amount={price.oldAmount} hasSymbol={false} mod="large" oldPrice />
          </div>
        ) : (
          <Price amount={price.amount} hasSymbol={false} mod="large" />
        )}
      </div>
      {rootCategory && (
        <Text
          text={rootCategory.type.value.title}
          mod="text"
          fontSize="12px"
          fontWeight="600"
          extend={css.category}
        />
      )}
    </div>
  );
});
