import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface ICartItemRuleKeys {
  ci_wrp: {};
  ci_img: {};
  title: {};
  info: {};
  info_props: {};
  calc: {};
  text: {};
  text_accent: {};
  calc_btn: {};
  info_bottom: {};
  side_btn_dell: {};
  slide_btn: {};
  sw_container: {};
  old_price: {};
}

export type ICartItemRules = IRuleFn<ICartItemRuleKeys>;

const cartitemRuleFn: ICartItemRules = props => {
  const { colorBlack, colorWhite, colorGrey, colorDarkGrey } = props.theme;

  return {
    sw_container: {
      padding: '12px 0',
    },

    ci_wrp: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: '12px',
      color: colorBlack,
    },

    ci_img: {
      width: '100px',
      height: '100px',
      borderRadius: '8px',
      flexShrink: '0',
      '& img': {
        borderRadius: '8px',
        width: '100%',
        objectFit: 'cover',
      },
    },
    title: {
      marginBottom: '4px',
      textTransform: 'uppercase!important',
    },
    text: {
      fontSize: '12px',
      lineHeight: '12px',
      fontWeight: '300',
    },
    text_accent: {
      fontWeight: '500',
      paddingLeft: '1px',
    },
    info: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    info_props: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      '& p': {
        marginRight: '12px',
      },
    },
    calc: {
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
    },
    calc_btn: {
      width: '24px',
      height: '24px',
      borderRadius: '4px',
      background: colorGrey,
      border: 'none',
      outline: 'none',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    info_bottom: {
      marginTop: '12px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '10px',
    },

    slide_btn: {
      width: '44px',
      height: 'auto',
      '& button': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        borderRadius: '8px',
        background: colorWhite,
        border: 'none',
        outline: 'none',
      },
    },
    side_btn_dell: {
      '& button': {
        background: colorBlack,
      },
    },
    old_price: {
      fontSize: '12px',
      fontWeight: '700',
      lineHeight: '18px',
      letterSpacing: '-0.14px',
      color: colorDarkGrey,
      textDecoration: 'line-through',
      textAlign: 'right',
    },
  };
};

export const cartitemRules = createCachedStyle(cartitemRuleFn);
