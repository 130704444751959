import { FC, memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import Text from '../UI/Text/Text';
import { priceRules } from './price.style';
import useCurrency from 'src/hooks/useCurrency';
import { telegramStarsString } from 'src/utils/constants';

interface CommonProps {
  amount: number | bigint;
  mod: 'large' | 'small';
  extend?: string;
  oldPrice?: boolean;
  // currency: string
}

type ConditionalProps =
  | {
      hasSymbol: true;
      isPositive: boolean;
    }
  | { hasSymbol: false; isPositive?: never };

type IProps = CommonProps & ConditionalProps;

export const Price: FC<IProps> = memo(function Price(props) {
  const { amount, isPositive, hasSymbol, mod, extend, oldPrice } = props;
  const { css } = useStyle(priceRules, { oldPrice });

  const currency= useCurrency()

  let symbol = '';

  if (hasSymbol && isPositive) {
    symbol = '+';
  } else if (hasSymbol && !isPositive) {
    symbol = '-';
  }

  return (
    <Text
      text={`${symbol} ${amount.toLocaleString('ru')} ${currency !== telegramStarsString? currency: ''}`.trim()}
      mod="text"
      fontSize={mod === 'large' && oldPrice ? '16px' : mod === 'large' ? '20px' : '12px'}
      lineHeight={mod === 'large' && oldPrice ? '18px' : mod === 'large' ? '22px' : '18px'}
      fontWeight={oldPrice ? '700' : '800'}
      whiteSpace="nowrap"
      extend={[css.price, extend].join(' ')}
      svg={currency === telegramStarsString? currency : undefined}
    />
  );
});
