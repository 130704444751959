import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IStoreInfoKeys {
  wrapper: {}
  storeAvatar: {}
  storeFocus: {}
}

export type IStoreInfo = IRuleFn<IStoreInfoKeys>;

const StoreInfoRuleFn: IStoreInfo = props => {
  const { colorDarkGrey } = props.theme;
  const {} = props;

  return {
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 10,
    },
    storeAvatar: {
      width: 38,
      height: 38,
      borderRadius: '50%',
      objectFit: 'cover'
    },
    storeFocus: {
      color: colorDarkGrey,
    }
  }

}

export const StoreInfoRules = createCachedStyle(StoreInfoRuleFn);