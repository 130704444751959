import { FC, memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { NothingWasFoundRules } from './NothingWasFound.style';
import Text from '../UI/Text/Text';
import { Button } from '../UI/Button/Button';
import { useTheme } from 'src/utils/theme/useTheme';
import {useTranslation} from '../../utils/i18n/hooks/useTranslation';

interface IProps {
  onClick: () => void;
}

export const NothingWasFound: FC<IProps> = memo(function NothingWasFound(props) {
  const { onClick } = props;
  const { css } = useStyle(NothingWasFoundRules);
  const { theme } = useTheme();
  const { t } = useTranslation()

  return <div className={css.wrapper}>
    <Text mod='title' text={t('nothingWasFound.nothingFound')} extend={css.title} />
    <Text mod='text' text={t('nothingWasFound.tryChangingTheFilters')} fontSize='14px' lineHeight='18px' fontWeight='500' />
    <Button text={t('nothingWasFound.resetFilters')} propsStyles={{padding: 16, width: '100%', background: theme.colorBlack}} extend={css.resetButton} onClick={onClick} />
  </div>;
});
