import { CourseType, Currency, DeliveryType, EventType, ProductType, ServiceType } from '@teleport/schemas-protobuf';
import { hexRegex } from '../hexToRgb';
import { IConfig, IRemoteConfig } from './config.interface';
import { IConfigStoreFocus } from 'src/types/storeFocus';

export async function getConfig(): Promise<IConfig> {
  if (process.env.NODE_ENV === 'development') {
    // TODO Fix enums
    return {
      portApiUrl: 'https://spain-api.apps.teleportapp.store/',
      accentColor: '#eb4034',
      deliveryType: removeBackendPrefix('DELIVERY_TYPE_REQUEST', 'deliveryType'),
      language: removeBackendPrefix('LANGUAGE_EN', 'language'), // todo change to en
      currency: removeBackendPrefix('CURRENCY_RUB', 'currency'),
      cartEnabled: true,
      portBotUsername: 'TLPRT999A_Bot',
      storeLogo: 'https://upload.wikimedia.org/wikipedia/commons/8/87/Revolt-Logo-Black.jpg',
      storeName: 'PORT DEV',
      storeFocus: getStoreFocusObject('PRODUCT_TYPE_GARDEN'), 
      codeName: 'code',
    };
  }

  const response = await fetch('/config.json');

  try {
    const config: IRemoteConfig = await response.json();

    return {
      portApiUrl: config.router['port-api'],
      deliveryType: removeBackendPrefix(config.deliveryType, 'deliveryType'),
      language: removeBackendPrefix(config.language, 'language'),
      currency: removeBackendPrefix(config.currency, 'currency'),
      accentColor: hexRegex.test(config.accentColor) ? config.accentColor : '#3390ec',
      portBotUsername: config.portBotUsername,
      cartEnabled: config.cartEnabled,
      storeLogo: config.storeLogo,
      storeName: config.storeName,
      storeFocus: getStoreFocusObject(config.storeFocus) ,
      codeName: config.codeName,
    };
  } catch (error) {
    console.error('Could not load config', error);

    return null;
  }
}

// FIX ME
function removeBackendPrefix(string: any, configKey: 'deliveryType' | 'language' | 'currency') {
  if(!string) return undefined;

  switch (configKey) {
    case 'deliveryType':
      return Number(DeliveryType[string.replace('DELIVERY_TYPE_', '')]);
    case 'language':
      return string.replace('LANGUAGE_', '').toLowerCase();
    case 'currency':
      return Number(Currency[string.replace('CURRENCY_', '')]);
  }
}

// FIX ME
function getStoreFocusObject(string) {
  let enumNumber: number;
  const storeFocus: IConfigStoreFocus = {
    type: null,
    enumNumber: null,
  }

  if(!string) return storeFocus;

  if(ProductType[string.replace('PRODUCT_TYPE_', '')]) {
    enumNumber = Number(ProductType[string.replace('PRODUCT_TYPE_', '')]);
    storeFocus.enumNumber = enumNumber;
    storeFocus.type = 'ProductType';
    return storeFocus
  }

  if(EventType[string.replace('EVENT_TYPE_', '')]) {
    enumNumber = Number(EventType[string.replace('EVENT_TYPE_', '')]);
    storeFocus.enumNumber = enumNumber;
    storeFocus.type = 'EventType';
    return storeFocus
  }

  if(ServiceType[string.replace('SERVICE_TYPE_', '')]) {
    enumNumber = Number(ServiceType[string.replace('SERVICE_TYPE_', '')])
    storeFocus.enumNumber = enumNumber
    storeFocus.type = 'ServiceType';
    return storeFocus
  }

  if(CourseType[string.replace('COURSE_TYPE_', '')]) {
    enumNumber = Number(CourseType[string.replace('COURSE_TYPE_', '')])
    storeFocus.enumNumber = enumNumber;
    storeFocus.type = 'CourseType';
    return storeFocus
  }
  
  return storeFocus
}


