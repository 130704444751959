import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface IButtonBasketRuleKeys {
  counter: {};
}

export type IButtonBasketRules = IRuleFn<IButtonBasketRuleKeys>;

const buttonBasketRuleFn: IButtonBasketRules = props => {
  const { colorWhite, colorBlack } = props.theme;

  return {
    counter: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: '12px',
      right: '11px',
      padding: '4px',
      minWidth: 16,
      height: 16,
      borderRadius: '50%',
      background: colorWhite,

      // txt
      fontSize: '12px',
      lineHeight: '18px',
      fontWeight: 700,
      textAlign: 'center',
      color: colorBlack,
    },
  };
};

export const buttonBasketRules = createCachedStyle(buttonBasketRuleFn);
