import ReactDOM from 'react-dom/client';
import { RendererProvider, ThemeProvider } from 'react-fela';
import { Provider } from 'react-redux';
import App from './App';
import { renderer } from './styles/fela';

import { ThemeName } from './utils/theme/theme.interface';
import { getTheme } from './utils/theme/getTheme';
import { getStore } from './redux/store';
import { TelegramProvider } from './utils/telegramProvider';
import './index.css';
import { IConfig } from './utils/config/config.interface';
import StoreNotWorking from './components/StoreNotWorking/StoreNotWorking';
import { isConfigValid } from './utils/config/isConfigValid';
import { setConfig, setTranslation } from './redux/slices/configSlice';
import { Theme } from './components/Theme/Theme';
import { getFavouritesProducts } from './redux/slices/favouritesSlice';
import { getStatus } from './redux/slices/mainSlice';
import { ITranslation, Language } from './utils/i18n/i18n.interface';

export function render(config: IConfig, translation: ITranslation) {
  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
  // todo unify into one render
  if (!isConfigValid(config)) {
    const store = getStore(config);
    store.dispatch(getStatus());
    store.dispatch(setTranslation({ translation, language: Language[config.language]}));

    root.render(
      <TelegramProvider>
        <Provider store={store}>
          <ThemeProvider theme={getTheme(ThemeName.Light)}>
            <RendererProvider renderer={renderer}>
              <StoreNotWorking reasonText={'Invalid config'} accentColor={config.accentColor} />
            </RendererProvider>
          </ThemeProvider>
        </Provider>
      </TelegramProvider>,
    );
  } else {
    const store = getStore(config);
    store.dispatch(setConfig(config));
    store.dispatch(getFavouritesProducts());
    store.dispatch(setTranslation({ translation, language: Language[config.language]}));
    root.render(
      // disable until figure out how to prevent duplicate requests
      // <React.StrictMode>
      <TelegramProvider>
        <Provider store={store}>
          <Theme>
            <RendererProvider renderer={renderer}>
              <App />
            </RendererProvider>
          </Theme>
        </Provider>
      </TelegramProvider>,
      // </React.StrictMode>,
    );
  }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
