import {memo, useCallback, useEffect, useMemo, useState} from 'react';
import { ReactComponent as IconSearch } from 'src/assets/icons-clear/search.svg';
import { useStyle } from 'src/utils/theme/useStyle';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { resetSearch, searchState, setOpen, setValue } from '../../redux/slices/searchSlice';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { RoutePath } from 'src/providers/AppRouter/routeConfig';
import { searchRules } from './search.style';
import { Icon } from '../Icon/Icon';
import { useScrollBlock } from 'src/hooks/useScrollBlock';
import { debounce } from 'src/utils/debounce';
import { Spinner } from '../UI/Spinner/Spinner';
import { NetworkStatus } from 'src/utils/connect/connectConstant';
import Text from '../UI/Text/Text';
import { categoriesState } from '../../redux/slices/categoriesSlice';
import useBackButton from 'src/hooks/useBackButton';
import { Button } from '../UI/Button/Button';
import { useTheme } from 'src/utils/theme/useTheme';
import { filtersState } from 'src/redux/slices/filterSlice';
import { FilterTranslator } from 'src/redux/translators/filterTranslator';
import {useTranslation} from '../../utils/i18n/hooks/useTranslation';
import { getProductsBySearch } from '../../redux/api/getProductsBySearch';

const Search = function Search() {
  const location = useLocation();
  const navigate = useNavigate();
  const [blockScroll, allowScroll] = useScrollBlock();
  // const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const { theme } = useTheme();
  const BackButton = useBackButton();

  const dispatch = useAppDispatch();
  const { value: searchValue, isOpen: open, products: foundProducts, networkStatus: searchNetworkStatus } = useAppSelector(searchState);
  const { selectedCategory, selectedSubCategory } = useAppSelector(categoriesState);
  const { selectionId } = useParams();
  const [inputEl, setInputEl] = useState<HTMLInputElement>(null);
  const { filters, selectedSorting, selectedFilters } = useAppSelector(filtersState);
  const { css } = useStyle(searchRules, { open });

  const cachedCheckInputKeyup = useCallback(
    function checkInputKeyup(event) {
      if (event.key === 'Enter') {
        if (location.pathname === '/' || location.pathname === '/catalog' || location.pathname.includes('/product')) {
          navigate(RoutePath.suggestions);
          // return;
        }
        dispatch(setOpen(false));
        event.currentTarget.blur();
      }
    },
    [navigate, dispatch, location],
  );

  const searchRef = useCallback(
    (node: HTMLInputElement) => {
      if (node !== null) {
        node.addEventListener('keyup', cachedCheckInputKeyup);
        setInputEl(node);
      }
    },
    [cachedCheckInputKeyup],
  );

  useEffect(() => {
    if (open) {
      blockScroll();
    } else {
      allowScroll();
    }

    return () => {
      allowScroll();
    };
  }, [open, allowScroll, blockScroll]);

  useEffect(() => {
    BackButton.onClickCustom(goBack);

    function goBack() {
      inputEl.blur();
      dispatch(setOpen(false))
    }

    if(!open) {
      BackButton?.offClickCustom(goBack);
      if(location.pathname === '/' ) {
        BackButton.hide();
      }
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, inputEl, open, location, dispatch]);

  const sendSearchRequest = useCallback(
    (query: string) => {
      if (query.length < 3) return;

      const filtersForRequest = [];
      
      if(selectedFilters.length > 0) {
        filters.forEach(filter => filtersForRequest.push(filter))
      }

      filtersForRequest.push(FilterTranslator.toSearchFilter(query))

      if (selectedCategory) {
        filtersForRequest.push(FilterTranslator.toCategoryFilter(selectedCategory))
      }

      if (selectedSubCategory) {
        filtersForRequest.push(FilterTranslator.toSubCategoryFilter(selectedSubCategory))
      }

      if (selectionId) {
        filtersForRequest.push(FilterTranslator.toSelectionFilter(selectionId))
      }

      dispatch(getProductsBySearch({
        filters: filtersForRequest, 
        sorting: selectedSorting.sortingNumber
      }));
    },
    [
      dispatch, 
      filters, 
      selectedSorting, 
      selectedCategory, 
      selectedSubCategory, 
      selectionId, 
      selectedFilters,
    ],
  );

  const sendSearchRequestDebounce = debounce(sendSearchRequest, 1000);

  const handleChange = event => {
    const value = event.target.value;
    dispatch(setValue(value));
    sendSearchRequestDebounce(value);
    if (value === '') {
      dispatch(resetSearch());
    }
  };

  const openSearch = () => {
    dispatch(setOpen(true));
    inputEl.focus();
  };

  const closeSearch = () => {
    dispatch(resetSearch());
  };

  const renderSearchResults = useMemo(() => {
    if (searchNetworkStatus === NetworkStatus.Loading) {
      return (
        <div className={css.spinnerWrapper}>
          <Spinner />
        </div>
      )
    }

    if (searchNetworkStatus === NetworkStatus.Done) {
      if (!foundProducts.length) {
        return (
          <Text
            text="По вашему запросу ничего не нашлось :("
            mod="text"
            fontSize="18px"
            lineHeight="20px"
          />
        );
      }

      return foundProducts.map(el => (
        <Link key={el.uuid} to={`/product/${el.uuid}`} className={css.resultEl}>
          {el.title}
        </Link>
      ))
    }

    return (
      <Text
        text="Что будем искать?"
        mod="text"
        fontSize="18px"
        lineHeight="20px"
      />
    )
  }, [searchNetworkStatus, foundProducts, css]);

  return (
    <div>
      <Button
        icon='search-clear'
        propsStyles={{padding: 9, width: 38, height: 38, background: theme.colorGrey, borderRadius: 8 }}
        extend={css.searchButton}
        onClick={openSearch}
      />
      <div className={css.searchResult}>
        <div className={css.searchInput}>
          <IconSearch className={css.searchIcon} />
          <input
            ref={searchRef}
            placeholder={t('search.search')}
            value={searchValue}
            onChange={handleChange}
          />
          {open && searchValue && (
            <button className={css.searchClose} onClick={closeSearch}>
              <Icon name="cross" />
            </button>
          )}
        </div>
        {open && renderSearchResults}
      </div>
    </div>
  );
};

export default memo(Search);
