import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IProductDescriptionKeys {
  wrapper: {};
  title: {};
  text: {};
  icon: {};
  button: {};
}

interface IProductDescriptionProps {
  open: boolean;
}

export type IProductDescriptions = IRuleFn<IProductDescriptionKeys, IProductDescriptionProps>;

const productDescriptionRuleFn: IProductDescriptions = props => {
  const { mainColor } = props.theme;
  const { open } = props;

  return {
    wrapper: {
      paddingTop: 16,
      paddingBottom: 16,
    },
    title: {
      marginBottom: '12px',
    },
    text: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': open ? 'none' : 3,
      '-webkit-box-orient': 'vertical',
    },
    button: {
      border: 'none',
      background: 'none',
      display: 'flex',
      alignItems: 'center',
      columnGap: '4px',

      // txt
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: '500',
      color: mainColor,
    },
    icon: {
      width: 12,
      height: 12,
      transform: open ? 'rotate(-90deg)' : 'rotate(90deg)',
    },
  };
};

export const productDescriptionRules = createCachedStyle(productDescriptionRuleFn);
