import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { NetworkStatus } from '../../utils/connect/connectConstant';
import { getMetadata } from '../api/getMetadata';
import { RootState } from '../store';
import { RootCategory, SubCategory } from '@teleport/schemas-protobuf';
import { categoriesTranslator } from '../translators/metadataTranslator';
import { ICategory } from 'src/types/categories';

interface ICategoriesState {
  selectedCategory: RootCategory | null;
  selectedSubCategory: SubCategory | null;
  categories: ICategory[];
  networkStatus: NetworkStatus;
}

const initialState: ICategoriesState = {
  selectedCategory: null,
  selectedSubCategory: null,
  categories: [],
  networkStatus: NetworkStatus.None,
};

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setCategory: (state, action: PayloadAction<RootCategory>) => {
      state.selectedCategory = action.payload;
    },
    setSubCategory: (state, action: PayloadAction<SubCategory>) => {
      state.selectedSubCategory = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getMetadata.pending, state => {
      state.networkStatus = NetworkStatus.Loading;
    });
    builder.addCase(getMetadata.fulfilled, (state, action) => {
      state.categories = categoriesTranslator.fromStatusResponse(action.payload);
      state.networkStatus = NetworkStatus.Done;
    });
    builder.addCase(getMetadata.rejected, state => {
      state.networkStatus = NetworkStatus.Failed;
    });
  },
});

export const { setCategory, setSubCategory } = categoriesSlice.actions;

// selectors
export const selectorCategory = (state: RootState) => state.categories.categories;
export const selectorSelectedCategory = (state: RootState) => state.categories.selectedCategory;
const selectorSelectedSubCategory = (state: RootState) => state.categories.selectedSubCategory;

export const categoriesState = (state: RootState) => state[categoriesSlice.name];
export const selectedCategoryData = createSelector(
  [selectorSelectedCategory, selectorSelectedSubCategory],
  (selectedCategory, selectedSubCategory) => {
    return { selectedCategory, selectedSubCategory };
  },
);
