import { FC, memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { StoreInfoRules } from './StoreInfo.style';
import Text from '../UI/Text/Text';
import { useAppSelector } from 'src/hooks/redux';
import { useProductTypeList } from 'src/hooks/useProductTypeList';
import { configState } from 'src/redux/slices/configSlice';

// interface IProps {}

export const StoreInfo: FC = memo(function StoreInfo(props) {
  const {} = props;
  const { css } = useStyle(StoreInfoRules);
  const { config } = useAppSelector(configState);
  const {storeLogo, storeFocus, storeName} = config
  const stroreFocusText = useProductTypeList(storeFocus)

  return <div className={css.wrapper}>
    <img src={storeLogo} className={css.storeAvatar} />
    <div>
      <Text mod='title' text={storeName} fontWeight='600' fontSize='14' lineHeight='16px' /> 
      <Text mod='title' text={stroreFocusText} fontWeight='400' fontSize='12' lineHeight='14px' extend={css.storeFocus}   /> 
    </div>
  </div>;
});