import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { MakeOrderFormRules } from './MakeOrderForm.style';
import { Button } from '../UI/Button/Button';
import { Input } from '../UI/Input/Input';
import { SubmitHandler, useForm } from 'react-hook-form';
import { VALIDATION_ERRORS } from 'src/types/validation';
import Container from '../Container/Container';
import Text from '../UI/Text/Text';
import useCurrency from 'src/hooks/useCurrency';
import { useModalState } from 'src/hooks/useModalState';
import { ModalResult } from '../ModalResult/ModalResult';
import {useTranslation} from '../../utils/i18n/hooks/useTranslation';

interface IOrderedProduct {
  uuid: string;
  price: number;
}

interface IProps {
  products: IOrderedProduct[];
}

interface IFormMakeOrder {
  name: string;
  phone: string;
  email: string;
}

export const MakeOrderForm: FC<IProps> = memo(function MakeOrderForm(props) {
  const { products } = props;
  const isSingleProduct = products.length > 1;
  const currency = useCurrency();
  const [footerHeight, setFooterHeight] = useState(0);
  const [renderModal, activeModal, openModal, closeModal] = useModalState();
  const { css } = useStyle(MakeOrderFormRules, {
    footerHeight: footerHeight,
  });
  const {t} = useTranslation();

  const [orderAmount, setOrderAmount] = useState(0);

  useEffect(() => {
    if (isSingleProduct) {
      const product = products[0];
      if(product) {
        setOrderAmount(product.price);
      }
    } else {
      let sum = 0;
      products.forEach(el => (sum += el.price));
      setOrderAmount(sum);
    }
  }, [isSingleProduct, products]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      phone: '',
    },
  });

  const onSubmit: SubmitHandler<IFormMakeOrder> = () => {
    openModal();
  };

  const footerRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setFooterHeight(node.clientHeight);
    }
  }, []);

  return (
    <>
      <form className={css.wrapper} onSubmit={handleSubmit(onSubmit)}>
        <Container>
          <div className={css.fieldWrapper}>
            <Input
              {...register('name', { required: VALIDATION_ERRORS.REQUIRED })}
              controlled={false}
              type="text"
              label={t('makeOrderForm.name')}
              placeholder={t('makeOrderForm.enterYourName')}
              maxLength={200}
              errorMessage={errors.name?.message}
            />
          </div>
          <div className={css.fieldWrapper}>
            <Input
              {...register('phone', { required: VALIDATION_ERRORS.REQUIRED })}
              controlled={false}
              type="text"
              label={t('makeOrderForm.phoneNumber')}
              placeholder={t('makeOrderForm.enterYourPhoneNumber')}
              maxLength={200}
              errorMessage={errors.phone?.message}
            />
          </div>
          <div className={css.fieldWrapper}>
            <Input
              {...register('email')}
              controlled={false}
              type="text"
              label={t('makeOrderForm.email')}
              placeholder={t('makeOrderForm.enterYourEmail')}
              maxLength={200}
              errorMessage={errors.email?.message}
            />
          </div>

          <div className={css.footer} ref={footerRef}>
            <Button propsStyles={{ width: '100%', height: 62 }} hasGradient={true}>
              <div>
                <Text text={t('makeOrderForm.placeOrder')} mod="text" fontSize="16" fontWeight="700" lineHeight="18px" />
                <Text

                  text={`${t('makeOrderForm.toPay', orderAmount)}  ${currency}`}
                  mod="title"
                  fontSize="12px"
                  lineHeight="14px"
                  fontWeight="400"
                />
              </div>
            </Button>
          </div>
        </Container>
      </form>
      {renderModal && (
        <ModalResult
          onClose={closeModal}
          active={activeModal}
          success={true}
          text={t('makeOrderForm.weWillContactYouShortlyViaTheProvidedContactDetails')}
          title={t('makeOrderForm.orderPlaced')}
        />
      )}
    </>
  );
});
