import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface IWatermarkRuleKeys {
  wrapper: {};
  button: {};
}

export type IWatermarkRules = IRuleFn<IWatermarkRuleKeys>;

const watermarkRuleFn: IWatermarkRules = () => ({
  wrapper: {
    padding: '12px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: 8,

    // txt
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 600,
  },

  button: {
    background: 'none',
    outline: 'none',
    border: 'none',
  },
});

export const watermarkRules = createCachedStyle(watermarkRuleFn);
