import { FC, memo, ReactNode, useEffect } from 'react';
import { layoutRules } from './layout.style';
import { useStyle } from '../../utils/theme/useStyle';

interface ILayout {
  children: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
  mainFlexColumn?: boolean;
}
export const Layout: FC<ILayout> = memo(function Layout(props) {
  const { children, header, footer } = props;

  // Fix expand() bug !!!
  // The lower part of the app has disappeared
  // First, the mini-application opens halfway and then expands to the full screen. 
  // Because of this, there is some kind of bug with the height of the visible area.

  useEffect(() => {
    const overflow = 1;
    function setupDocument() {
      document.body.style.overflowY = 'hidden';
      document.body.style.marginTop = `${overflow}px`;
      document.body.style.height = window.innerHeight + overflow + 'px';
      document.body.style.paddingBottom = `${overflow}px`;
      // смещаем окно так, чтобы body оказался в верхнем левом углу
      window.scrollTo(0, overflow);
      // setTimeout(() => {
      //   document.body.style.marginTop = '';
      //   document.body.style.paddingBottom = '';
      //   window.scrollTo(0, 0);
      // }, 100)
    }
    setupDocument();
  }, []);

  const { css } = useStyle(layoutRules, {
    mainFlexColumn: props.mainFlexColumn,
  });

  return (
    <div className={`${css.root} root-layout`} style={{height: '100vh'}}>
      {header}
      <main className={css.main}>{children}</main>
      {footer}
    </div>
  );
});
