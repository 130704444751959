import { FC, memo, useEffect } from 'react';
import { Layout } from 'src/components/Layout/Layout';
import { useStyle } from 'src/utils/theme/useStyle';
import useLoader from 'src/hooks/useLoader';
import Text from 'src/components/UI/Text/Text';
import Container from 'src/components/Container/Container';
import { MakeOrderRules } from '../MakeOrder/MakeOrder.style';
import { MakeOrderForm } from 'src/components/MakeOrderForm/MakeOrderForm';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { getProductDetail, productState } from 'src/redux/slices/productDetailSlice';
import { NetworkStatus } from 'src/utils/connect/connectConstant';
import { useNavigate, useParams } from 'react-router-dom';
import useBackButton from 'src/hooks/useBackButton';

export const MakeSingleOrder: FC = memo(function MainPage() {
  const { css } = useStyle(MakeOrderRules);
  const BackButton = useBackButton();
  const navigate = useNavigate();
  const { productId } = useParams();
  const { showLoader, hideLoader } = useLoader();
  const dispatch = useAppDispatch();
  const { product, networkStatus } = useAppSelector(productState);

  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate(-1);
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  useEffect(() => {
    if (networkStatus === NetworkStatus.Loading) {
      showLoader();
    }
    if (networkStatus === NetworkStatus.Done) {
      hideLoader();
    }
  }, [networkStatus, hideLoader, showLoader]);

  useEffect(() => {
    dispatch(getProductDetail(productId));
  }, [dispatch, productId]);

  return (
    <Layout
      header={
        <Container extend={css.header} paddingTop={20} paddingBottom={10}>
          <Text text="Оформление заказа" mod="title" />
        </Container>
      }
    >
      <MakeOrderForm
        products={[{ price: Number(product?.price.amount), uuid: product?.uuid }]}
      />
    </Layout>
  );
});
