import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';
import {padding} from '../../utils/applyCSSProperty';

export interface IMakeOrderFormKeys {
  wrapper: {};
  fieldWrapper: {};
  footer: {};
}

interface IMakeOrderFormProps {
  footerHeight: number;
}

export type IMakeOrderForm = IRuleFn<IMakeOrderFormKeys, IMakeOrderFormProps>;

const MakeOrderFormRuleFn: IMakeOrderForm = props => {
  const { colorWhite } = props.theme;
  const { footerHeight } = props;

  return {
    wrapper: {
      minHeight: '100%',
      paddingBottom: footerHeight,
    },
    fieldWrapper: {
      ...padding(12, 0),
    },
    footer: {
      width: '100%',
      position: 'fixed',
      left: 0,
      bottom: 0,
      ...padding(12, 16, 30),
      backgroundColor: colorWhite,
    },
  };
};

export const MakeOrderFormRules = createCachedStyle(MakeOrderFormRuleFn);
