import { memo, useCallback } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { useTheme } from 'src/utils/theme/useTheme';
// import { useAppSelector } from 'src/hooks/redux';
import { Button } from '../UI/Button/Button';
import { headerRules } from './header.style';
import Container from '../Container/Container';
import { StoreInfo } from '../StoreInfo/StoreInfo';
import Search from '../Search/Search';
import { useAppSelector } from 'src/hooks/redux';
import { configState } from 'src/redux/slices/configSlice';

interface IProps {
  extend?: string;
  showFavoriteBtn?: boolean;
  openSortModalFn?: () => void;
  openModalFitersFn?: () => void;
}

const Header = function Header(props: IProps) {
  const { extend } = props;
  const { css } = useStyle(headerRules);
  const { theme } = useTheme();
  const { config } = useAppSelector(configState);
  const storeUrl = `https://t.me/${config.portBotUsername}/port?startapp=${config.codeName}`

  const shareProduct = useCallback(() => {
    navigator.share({ url: storeUrl });
  }, [storeUrl]);
  

  return (
    <header className={[css.header, extend].join(' ')}>
      <Container extend={css.headerInner}>
        <StoreInfo />
        <div className={css.buttons}>
          <Search />
          <Button icon='share' propsStyles={{padding: 9, width: 38, height: 38, background: theme.colorGrey, borderRadius: 8 }} extend={css.shareButton} onClick={shareProduct} />
        </div>
      </Container>
    </header>
  );
};

export default memo(Header);
