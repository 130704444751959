import { memo, useEffect, useRef, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { ReactComponent as ShevronBottom } from 'src/assets/icons/shevron-bottom-icon.svg';
import { Link } from 'react-router-dom';
import { droplistRules } from './DropList.style';
import { setCategory, setSubCategory } from '../../../redux/slices/categoriesSlice';
import { useAppDispatch } from 'src/hooks/redux';
import { ICategory } from 'src/types/categories';

interface IProps {
  data: ICategory;
}

const DropList = function DropList(props: IProps) {
  const { data } = props;
  const { productsCount, category, subCategories } = data;

  const descRef = useRef(null);
  const dispatch = useAppDispatch();

  // states
  const [open, setOpen] = useState(false);
  const [radioWrapHeight, setRadioWrapHeight] = useState<string>('0');

  const getHeight = () => {
    const innerElement = descRef.current as HTMLElement | null;
    if (innerElement !== null) return innerElement.offsetHeight;
    return 0;
  };

  useEffect(() => {
    setTimeout(() => {
      setRadioWrapHeight(open ? `${getHeight() + 1}px` : '0');
    }, 200);
  }, [open]);

  const { css } = useStyle(droplistRules, { open });

  const onClickSubCategory = event => {
    const subCategoryId = event.currentTarget.dataset.subcategoryId;
    const subCategory = subCategories.find(
      el => el.subCategory.uuid === subCategoryId,
    )?.subCategory;
    if (!subCategory) return;
    dispatch(setCategory(category));
    dispatch(setSubCategory(subCategory));
  };

  const onClickCategory = () => {
    dispatch(setCategory(category));
  };

  const toggleDropList = () => {
    if (!Boolean(subCategories.length)) return;
    setOpen(!open);
  };

  return (
    <div className={css.droplist}>
      <div className={css.droplistHeader} onClick={toggleDropList}>
        <Link
          to={`/category/${category.uuid}`}
          onClick={onClickCategory}
          className={css.droplistTitle}
        >
          {category.title}
        </Link>
        <span className={css.dropCount}>{productsCount}</span>
        {Boolean(subCategories.length) && <ShevronBottom className={css.icon} />}
      </div>

      <div className={css.dropWrp} style={{ height: radioWrapHeight }}>
        <div className={css.dropInner} ref={descRef}>
          {subCategories.map((el, i) => (
            <div className={css.dropRow} key={i}>
              <Link
                onClick={onClickSubCategory}
                data-subcategory-id={el.subCategory.uuid}
                to={`/subcategory/${el.subCategory.categoryUuid}/${el.subCategory.uuid}`}
              >
                {el.subCategory.title} <span className={css.span}>{el.productsCount}</span>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default memo(DropList);
