import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IPromoCode } from 'src/types/cart';
import { RootState } from '../store';
import { ClientCatalogProductFull } from '@teleport/schemas-protobuf';
import { NetworkStatus } from 'src/utils/connect/connectConstant';
import { createAppAsyncThunk } from '../../hooks/redux';

// TODO => заменить тип ITempCartItem на тот, который будет в возвращаться из апи корзины
export interface ITempCartItem extends ClientCatalogProductFull {
  count: number;
}

// const demoDataExample = {
//   "uuid":"d24d01d1-2c18-41b0-880a-7c531ecf6fff",
//   "title":"Plastic Green Lamp",
//   "description":"Power certain do in this without up. Album where where when though our in whom onto place those how whom.",
//   "imageUrls":["https://sesin.ru/images/teleport/01.jpg","http://www.chiefcutting-edge.biz/scalable","https://sesin.ru/images/teleport/04.png","https://sesin.ru/images/teleport/05.png","https://sesin.ru/images/teleport/04.png"],
//   "isFavorite":false,
//   "price":{"amount":"5972","discountEnabled":false,"oldAmount":"65974","discountInPercent":98},
//   "modifiers":[],
//   "selections":[{"title":"Hot","uuid":"1f1e602e-063b-4136-af22-ab5f228e6094"},{"title":"rated","uuid":"d62e5c95-d6e7-4562-b502-67f7234b68d7"}],
//   "categories":[{"rootCategory":{"uuid":"64fd5de0-2ffa-4ecd-ab6c-5b74b64d27e5","title":"bath and shower products"}},{"subCategory":{
//   "categoryUuid":"5d1593ae-992f-428f-b369-54ea8acc90d0","uuid":"6c9e339d-2ce1-4d25-b964-81ec1b2ba79c","title":"pet food"}}]
// }

interface CartState {
  products: ITempCartItem[];
  minPrice: number;
  promoCode?: IPromoCode;
  networkStatus: NetworkStatus;
}

// Асинхронный thunk для получения карточек товаров из localStorage
export const getCartProducts = createAppAsyncThunk<ITempCartItem[]>(
  'products/fetchProducts',
  async () => {
    const productsJson = localStorage.getItem('products');
    if (productsJson) {
      return JSON.parse(productsJson);
    }
    return [];
  },
);

const initialState: CartState = {
  promoCode: undefined,
  minPrice: 5000,
  products: [],
  networkStatus: NetworkStatus.None,
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    changeCartCount: (
      state,
      action: PayloadAction<{
        cartItemId: number | string;
        increase: boolean;
      }>,
    ) => {
      const { cartItemId, increase } = action.payload;
      const currentProduct = state.products.find(product => product.uuid === cartItemId);
      if (currentProduct) currentProduct.count += increase ? 1 : -1;
    },
    dellFromCart: (state, action: PayloadAction<{ cartItemId: number | string }>) => {
      state.products = state.products.filter(el => el.uuid !== action.payload.cartItemId);
    },
    addProductToCart: (state, action: PayloadAction<ClientCatalogProductFull>) => {
      const product = action.payload;
      const currentProduct = state.products.find(pr => pr.uuid === product.uuid);
      if (currentProduct) {
        currentProduct.count += 1;
      } else {
        //TODO => разобратьс с эни
        const tempCartItem: any = {
          ...product,
          count: 1,
        };
        state.products.push(tempCartItem);
      }
    },
    setPromoCode: (state, action: PayloadAction<IPromoCode | undefined>) => {
      state.promoCode = action.payload
        ? {
          name: action.payload.name,
          discount: action.payload.discount,
        }
        : undefined;
    },
  },
  extraReducers: builder => {
    builder.addCase(getCartProducts.pending, state => {
      state.networkStatus = NetworkStatus.Loading;
    });
    builder.addCase(getCartProducts.fulfilled, (state, action) => {
      state.products = action.payload;
      state.networkStatus = NetworkStatus.Done;
    });
    builder.addCase(getCartProducts.rejected, state => {
      state.networkStatus = NetworkStatus.Failed;
    });
  },
});

export const { changeCartCount, dellFromCart, setPromoCode, addProductToCart } = cartSlice.actions;
export const cartState = (state: RootState) => state[cartSlice.name];
