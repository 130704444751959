import { FC, memo, useEffect, useRef, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { ReactComponent as MinusIcon } from 'src/assets/icons/minus.svg';
import { ReactComponent as PlusIcon } from 'src/assets/icons/plus.svg';
import { useAppDispatch } from 'src/hooks/redux';
import { ITempCartItem, changeCartCount, dellFromCart } from '../../redux/slices/cartSlice';
import { Swiper, SwiperSlide } from 'swiper/react';
import { cartitemRules } from './cartitem.style';
import Text from '../UI/Text/Text';
import { ImageComponent } from '../ImageComponent/ImageComponent';
import useCurrency from 'src/hooks/useCurrency';
import { telegramStarsString } from 'src/utils/constants';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';

interface CommonProps {
  extend?: string;
  data: ITempCartItem;
}

type IProps = CommonProps;

export const CartItem: FC<IProps> = memo(function CartItem(props) {
  const { extend, data } = props;
  const { css } = useStyle(cartitemRules);

  const dispatch = useAppDispatch();

  const [reachedEnd, setReachedEnd] = useState(false);
  const swiperRef = useRef(null);

  // Обработчик события при достижении конца слайдера
  const handleReachEnd = () => {
    setReachedEnd(true);
  };

  useEffect(() => {
    if (reachedEnd && swiperRef.current && swiperRef.current.slides?.length) {
      swiperRef.current.slideTo(swiperRef.current.slides?.length - 1, 0);
    }
  }, [reachedEnd]);

  const currentCount = data.count ? data.count : 1;
  const currency= useCurrency()
  const isTelegramCurrency = currency === telegramStarsString

  const { t } = useTranslation()

  return (
    <Swiper
      ref={swiperRef}
      className={`${css.sw_container} ${extend}`}
      spaceBetween={16}
      slidesPerView="auto"
      initialSlide={1}
      speed={100}
      onReachEnd={handleReachEnd}
    >
      <SwiperSlide className={`${css.slide_btn}`}>
        <button>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.9346 2.39152C12.5546 2.13304 13.219 2 13.8901 2C14.5611 2 15.2256 2.13304 15.8456 2.39152C16.4654 2.64996 17.0286 3.02872 17.5029 3.50618C17.9774 3.98349 18.3541 4.55044 18.6109 5.17415C18.8678 5.79797 19 6.46661 19 7.14186C19 7.81711 18.8678 8.48575 18.6109 9.10957C18.3541 9.73333 17.9776 10.3001 17.503 10.7774C17.503 10.7774 17.5031 10.7774 17.503 10.7774L10.5555 17.7684C10.2486 18.0772 9.75098 18.0772 9.44406 17.7684L2.49653 10.7774C1.53832 9.8132 1 8.50545 1 7.14186C1 5.77827 1.53832 4.47052 2.49653 3.50631C3.45475 2.54211 4.75437 2.00042 6.10949 2.00042C7.46461 2.00042 8.76423 2.54211 9.72244 3.50631L9.99979 3.7854L10.277 3.50645C10.277 3.50649 10.277 3.5064 10.277 3.50645C10.7514 3.02892 11.3147 2.64998 11.9346 2.39152ZM13.8901 3.58167C13.4255 3.58167 12.9655 3.67377 12.5363 3.85272C12.1071 4.03167 11.7172 4.29395 11.3887 4.62459L10.5555 5.46301C10.2486 5.77184 9.75098 5.77184 9.44406 5.46301L8.61099 4.62472C7.94755 3.95714 7.04773 3.58209 6.10949 3.58209C5.17125 3.58209 4.27143 3.95714 3.60799 4.62472C2.94455 5.29231 2.57184 6.19775 2.57184 7.14186C2.57184 8.08597 2.94455 8.99141 3.60799 9.659L9.99979 16.0908L16.3916 9.659C16.7202 9.32852 16.981 8.936 17.1588 8.50412C17.3366 8.07224 17.4282 7.60934 17.4282 7.14186C17.4282 6.67438 17.3366 6.21148 17.1588 5.7796C16.981 5.34772 16.7203 4.95534 16.3917 4.62485C16.0633 4.29422 15.6731 4.03167 15.2439 3.85272C14.8147 3.67377 14.3547 3.58167 13.8901 3.58167Z"
              fill="#1C1916"
            />
          </svg>
        </button>
      </SwiperSlide>
      <SwiperSlide>
        <div className={[css.ci_wrp].join('')}>
          <div className={css.ci_img}>
            <ImageComponent src={data.imageUrls[0] ? data.imageUrls[0] : ''} />
          </div>
          <div className={css.info}>
            <div>
              <Text
                text={data.title}
                mod="text"
                fontSize={'14px'}
                lineHeight={'16px'}
                fontWeight="800"
                extend={css.title}
              />
              <div className={css.info_props}>
                <p className={css.text}>
                  {t('cartItem.size')} <span className={css.text_accent}>S</span>
                </p>
                <p className={css.text}>
                  {t('cartItem.color')} <span className={css.text_accent}>Синий</span>
                </p>
              </div>
              {data.price.oldAmount && (
                <div className={css.old_price}>{Number(data.price.oldAmount) * currentCount} {isTelegramCurrency? <img style={{width: 20}}  src={`/images/currencies/${currency}.svg`} alt='' /> : currency }</div>
              )}
            </div>

            <div className={css.info_bottom}>
              <div className={css.calc}>
                <button
                  onClick={() =>
                    dispatch(changeCartCount({ cartItemId: data.uuid, increase: false }))
                  }
                  className={css.calc_btn}
                >
                  <MinusIcon />
                </button>
                <span>{currentCount}</span>
                <button
                  onClick={() =>
                    dispatch(changeCartCount({ cartItemId: data.uuid, increase: true }))
                  }
                  className={css.calc_btn}
                >
                  <PlusIcon />
                </button>
              </div>
              <Text
                text={`${Number(data.price.amount) * currentCount}  ${isTelegramCurrency? '' : currency}`}
                mod="text"
                fontSize={'20px'}
                lineHeight={'22px'}
                fontWeight="700"
                extend={css.title}
                svg={isTelegramCurrency? currency : undefined}
              />
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide className={`${css.side_btn_dell} ${css.slide_btn}`}>
        <button onClick={() => dispatch(dellFromCart({ cartItemId: data.uuid }))}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.4 2.63636C8.18783 2.63636 7.98434 2.72256 7.83431 2.876C7.68429 3.02944 7.6 3.23755 7.6 3.45455V4.27273H12.4V3.45455C12.4 3.23755 12.3157 3.02944 12.1657 2.876C12.0157 2.72256 11.8122 2.63636 11.6 2.63636H8.4ZM14 4.27273V3.45455C14 2.80356 13.7471 2.17924 13.2971 1.71892C12.847 1.2586 12.2365 1 11.6 1H8.4C7.76348 1 7.15303 1.2586 6.70294 1.71892C6.25286 2.17924 6 2.80356 6 3.45455V4.27273H2.8C2.35817 4.27273 2 4.63904 2 5.09091C2 5.54278 2.35817 5.90909 2.8 5.90909H3.6V16.5455C3.6 17.1964 3.85286 17.8208 4.30294 18.2811C4.75303 18.7414 5.36348 19 6 19H14C14.6365 19 15.247 18.7414 15.6971 18.2811C16.1471 17.8208 16.4 17.1964 16.4 16.5455V5.90909H17.2C17.6418 5.90909 18 5.54278 18 5.09091C18 4.63904 17.6418 4.27273 17.2 4.27273H14ZM5.2 5.90909V16.5455C5.2 16.7625 5.28429 16.9706 5.43431 17.124C5.58434 17.2774 5.78783 17.3636 6 17.3636H14C14.2122 17.3636 14.4157 17.2774 14.5657 17.124C14.7157 16.9706 14.8 16.7624 14.8 16.5455V5.90909H5.2ZM8.4 8.36364C8.84183 8.36364 9.2 8.72995 9.2 9.18182V14.0909C9.2 14.5428 8.84183 14.9091 8.4 14.9091C7.95817 14.9091 7.6 14.5428 7.6 14.0909V9.18182C7.6 8.72995 7.95817 8.36364 8.4 8.36364ZM10.8 9.18182C10.8 8.72995 11.1582 8.36364 11.6 8.36364C12.0418 8.36364 12.4 8.72995 12.4 9.18182V14.0909C12.4 14.5428 12.0418 14.9091 11.6 14.9091C11.1582 14.9091 10.8 14.5428 10.8 14.0909V9.18182Z"
              fill="white"
            />
          </svg>
        </button>
      </SwiperSlide>
    </Swiper>
  );
});
