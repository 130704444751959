import { memo, useEffect, useRef } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import {
  FiltersTypes,
  IFilterRange,
  changeBoolFilter,
  changeMultiselectFilter,
  changeRangeFilterMax,
  changeRangeFilterMin,
  filtersState,
  resetFilters,
  setFilter,
  setRangeFilter,
} from '../../redux/slices/filterSlice';
import Modal, { ModalNames } from '../Modal/Modal';
import { modalFiltersRules } from './modalFilters.style';
import Toggler from '../UI/Toggler/Toggler';
import Text from '../UI/Text/Text';
import Chip from '../UI/Chip/Chip';
import RangeSlider from '../UI/RangeSlider/RangeSlider';
import { Button } from '../UI/Button/Button';
import { NetworkStatus } from 'src/utils/connect/connectConstant';
import { getMetadata } from '../../redux/api/getMetadata';
import {
  Filter_BoolFilter,
  Filter_MultiselectFilter,
  Filter_RangeFilter,
} from '@teleport/schemas-protobuf';
import { v4 } from 'uuid';
import { getProductsList } from '../../redux/api/getProductsList';
import { FilterTranslator } from 'src/redux/translators/filterTranslator';
import { getSearchValue } from 'src/redux/slices/searchSlice';
import { categoriesState } from 'src/redux/slices/categoriesSlice';
import { useParams } from 'react-router-dom';
import {useTranslation} from '../../utils/i18n/hooks/useTranslation';

interface IProps {
  onClose: () => void;
  active: boolean;
}

const ModalFilters = function ModalFilters(props: IProps) {
  const { onClose, active } = props;
  const dispatch = useAppDispatch();
  const formFiltersRef = useRef(null);
  const modalHeaderRef = useRef<HTMLDivElement>(null);
  const searchValue = useAppSelector(getSearchValue)
  const { selectedCategory, selectedSubCategory } = useAppSelector(categoriesState);
  const { selectionId } = useParams();
  const { t } = useTranslation();

  const { css } = useStyle(modalFiltersRules, {
    headerHeight: modalHeaderRef.current?.clientHeight,
  });

  const { filters, selectedSorting, networkStatus } = useAppSelector(filtersState);

  useEffect(() => {
    if (networkStatus === NetworkStatus.None) {
      dispatch(getMetadata());
    }
  }, [dispatch, networkStatus]);

  function applyFilters() {
    dispatch(resetFilters());

    const form = formFiltersRef.current;
    const formData = new FormData(form);

    const rangeFilter: IFilterRange = {
      type: FiltersTypes.RANGE,
      id: '',
      tag: '',
      minValue: 0,
      maxValue: 0,
    };

    for (const [key, value] of formData) {
      const tagRegex = /tag\:\w*\|/;
      const replaceRegex = /tag\:|\|/g;
      const matchArr = key.match(tagRegex);
      let tag = '';
      if (matchArr) {
        tag = matchArr[0].replace(replaceRegex, '');
      }
      // console.log(tag);

      if (key.toLowerCase().includes('range-min')) {
        rangeFilter.minValue = Number(value);
      } else if (key.toLowerCase().includes('range-max')) {
        rangeFilter.tag = tag;
        rangeFilter.id = v4();
        rangeFilter.maxValue = Number(value);
      } else {
        dispatch(
          setFilter({
            type: FiltersTypes.STANDART,
            id: v4(),
            tag: tag,
            value: String(value),
          }),
        );
      }
    }

    dispatch(setRangeFilter(rangeFilter));

    const filtersForRequest = [...filters]

    if (searchValue.length >= 3) {
      filtersForRequest.push(FilterTranslator.toSearchFilter(searchValue))
    }

    if (selectedCategory) {
      filtersForRequest.push(FilterTranslator.toCategoryFilter(selectedCategory))
    }

    if (selectedSubCategory) {
      filtersForRequest.push(FilterTranslator.toSubCategoryFilter(selectedSubCategory))
    }

    if (selectionId) {
      filtersForRequest.push(FilterTranslator.toSelectionFilter(selectionId))
    }

    dispatch(getProductsList({ 
      filters: filtersForRequest, 
      sorting: selectedSorting.sortingNumber
    }));

    onClose();
  }

  function isBoolFilter(_value: any, blockCase: string): _value is Filter_BoolFilter {
    return blockCase === 'boolFilter';
  }

  function isMultiselectFilter(_value: any, blockCase: string): _value is Filter_MultiselectFilter {
    return blockCase === 'multiselectFilter';
  }

  function isRangeFilter(_value: any, blockCase: string): _value is Filter_RangeFilter {
    return blockCase === 'rangeFilter';
  }

  function onChangeBoolFilter(event) {
    const tag = event.currentTarget.id;
    const checked = event.currentTarget.checked;
    dispatch(changeBoolFilter({ tag, checked }));
  }

  function onChangeMultiSelectFilter(event) {
    const tag = event.currentTarget.dataset.tag;
    const optionTitle = event.currentTarget.dataset.title;
    const checked = event.currentTarget.checked;
    dispatch(changeMultiselectFilter({ tag, optionTitle, checked }));
  }

  function onChangeRangeMinValue(event) {
    const input = event.currentTarget;
    const value = input.value;
    const tag = input.dataset.tag;

    dispatch(changeRangeFilterMin({ tag, minValue: value }));
  }

  function onChangeRangeMaxValue(event) {
    const input = event.currentTarget;
    const value = input.value;
    const tag = input.dataset.tag;

    dispatch(changeRangeFilterMax({ tag, maxValue: value }));
  }

  return (
    <Modal
      active={active}
      name={ModalNames.FILTERS}
      onClose={onClose}
      propsStyles={{ paddingBottom: 0 }}
    >
      <div ref={modalHeaderRef} className={css.header}>
        <Text text={t('modalFilters.filters')} mod="title" />
      </div>
      <div className={css.modalInner}>
        <form className={css.content} ref={formFiltersRef}>
          {filters.map(filter => {
            const { filterDefaultState, tag, title } = filter;
            const value = filterDefaultState.value;

            if (isBoolFilter(value, filterDefaultState.case)) {
              return (
                <div key={tag} className={[css.filterEl, css.toggle].join(' ')}>
                  <Text
                    text={title}
                    mod="title"
                    fontSize="12px"
                    lineHeight="18px"
                    extend={css.title}
                  />
                  <Toggler
                    id={tag}
                    name={`tag:${tag}|`}
                    value={title}
                    checked={value.checked}
                    onChange={onChangeBoolFilter}
                  />
                </div>
              );
            }
            if (isMultiselectFilter(value, filterDefaultState.case)) {
              return (
                <div key={tag} className={css.filterEl}>
                  <Text
                    text={filter.title}
                    mod="title"
                    fontSize="12px"
                    lineHeight="18px"
                    extend={[css.title, css.titleMargin].join(' ')}
                  />

                  <div className={css.enumInner}>
                    {value.availableOptions.map(option => (
                      <Chip
                        attributes={{ 'data-tag': tag, 'data-title': option.title }}
                        key={option.title}
                        name={`tag:${tag}|name:${option.title}`}
                        text={option.title}
                        value={option.title}
                        checked={option.checked}
                        onChange={onChangeMultiSelectFilter}
                        type="checkbox"
                      />
                    ))}
                  </div>
                </div>
              );
            }
            if (isRangeFilter(value, filterDefaultState.case)) {
              return (
                <div className={css.filterEl} key={tag}>
                  <Text
                    text={filter.title}
                    mod="title"
                    fontSize="12px"
                    lineHeight="18px"
                    extend={[css.title, css.titleMargin].join(' ')}
                  />
                  <RangeSlider
                    attributes={{ 'data-tag': tag }}
                    min={Number(value.min)}
                    max={Number(value.max)}
                    presentMinValue={Number(value.gte)}
                    presentMaxValue={Number(value.lte)}
                    name={`tag:${tag}|`}
                    step={1}
                    mod="price"
                    changeMinValue={onChangeRangeMinValue}
                    changeMaxValue={onChangeRangeMaxValue}
                  />
                </div>
              );
            }

            return null;
          })}
        </form>
        <footer className={css.footer}>
          <Button
            text={t('modalFilters.apply')}
            propsStyles={{ width: '100%', height: '62px' }}
            onClick={applyFilters}
          />
        </footer>
      </div>
    </Modal>
  );
};

export default memo(ModalFilters);
