import { memo, useEffect, useRef } from 'react';
import { ReactComponent as IconArrow } from 'src/assets/icons/arrow-right.svg';
import { useStyle } from 'src/utils/theme/useStyle';
import ProductCard, { ProductCardSize } from '../ProductCard/ProductCard';
// import { container } from "src/styles/global";
import Container from '../Container/Container';
import { SelectionRules } from './selection.style';
import { getSelectionProducts } from '../../redux/api/selections/getSelectionProducts';
import { useAppDispatch } from 'src/hooks/redux';
import { ISelectionWithProducts } from 'src/types/selections';
import { NetworkStatus } from 'src/utils/connect/connectConstant';
import { Link } from 'react-router-dom';
import { AppRoutes } from 'src/providers/AppRouter/routeConfig';

interface IProps {
  extend?: any;
  propsStyles?: IStyles;
  selectionData: ISelectionWithProducts;
  // title: string;
  // selectionId: string;
  location: 'productPage' | 'mainPage';
}

export interface IStyles {
  marginBottom?: number;
}

const Selection = function Selection(props: IProps) {
  const { propsStyles, extend, location, selectionData } = props;
  const { uuid, title, products, networkStatus } = selectionData;
  const { css } = useStyle(SelectionRules, {
    marginBottom: propsStyles?.marginBottom,
  });
  const sliderRef = useRef<any>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (networkStatus === NetworkStatus.None) {
      dispatch(getSelectionProducts({ selectionUuid: uuid, selectionLocation: location }));
    }
  }, [location, networkStatus, uuid, dispatch]);

  // swiper settings
  useEffect(() => {
    const swiperEl = sliderRef.current;

    if (!swiperEl) return;

    const swiperParams = {
      spaceBetween: 8,
      slidesPerView: 'auto',
      slidesOffsetBefore: 16,
      slidesOffsetAfter: 16,
      injectStyles: [],
    };

    Object.assign(swiperEl, swiperParams);

    swiperEl.initialize();
  }, []);

  return (
    <section className={[css.section, extend].join(' ')}>
      <Container extend={css.header}>
        <h2 className={css.title}>{title}</h2>
        {location !== 'productPage' && (
          <Link to={`${AppRoutes.SELECTION}/${uuid}`}>
            <IconArrow />
          </Link>
        )}
      </Container>
      <swiper-container
        ref={sliderRef}
        // @ts-ignore:next-line
        init={false}
      >
        {products.map(product => (
          // @ts-ignore:next-line
          <swiper-slide class={css.slide} key={product.uuid}>
            <ProductCard data={product} size={ProductCardSize.SMALL} />
          </swiper-slide>
        ))}
      </swiper-container>
    </section>
  );
};

export default memo(Selection);
