import { ProductType } from '@teleport/schemas-protobuf';
import { useMemo } from 'react';
import { IConfigStoreFocus } from 'src/types/storeFocus';
import {useTranslation} from '../utils/i18n/hooks/useTranslation';

interface IStoreFocus {
  text: string,
  value: ProductType,
}

export const useProductTypeList = (storeFocusObj: IConfigStoreFocus) => {
  const {t} = useTranslation();
  
  const storeFocuses: {
    'ProductType': IStoreFocus[], 
    'EventType': IStoreFocus[],
    'ServiceType': IStoreFocus[],
    'CourseType': IStoreFocus[]
  } = useMemo(() => ({
    'ProductType': [ 
      {
        text: t('productType.electronics'),
        value: ProductType.ELECTRONICS,
      },
      {
        text: t('productType.clothes'),
        value: ProductType.CLOTH,
      },
      {
        text: t('productType.shoes'),
        value: ProductType.SHOES,
      },
      {
        text: t('productType.homeAndGarden'),
        value: ProductType.GARDEN,
      },
      {
        text: t('productType.kids'),
        value: ProductType.CHILDREN,
      },
      {
        text: t('productType.healthAndBeauty'),
        value: ProductType.BEAUTY,
      },
      {
        text: t('productType.homeAppliances'),
        value: ProductType.HOUSEHOLD_APPLIANCES,
      },
      {
        text: t('productType.sportAndLeisure'),
        value: ProductType.SPORT,
      },
      {
        text: t('productType.homeImprovement'),
        value: ProductType.REPAIR,
      },
      {
        text: t('productType.food'),
        value: ProductType.FOOD,
      },
      {
        text: t('productType.pharmacy'),
        value: ProductType.PHARMACY,
      },
      {
        text: t('productType.pets'),
        value: ProductType.PET,
      },
      {
        text: t('productType.books'),
        value: ProductType.BOOK,
      },
      {
        text: t('productType.tourismFishingHunting'),
        value: ProductType.TOURISM,
      },
      {
        text: t('productType.automotive'),
        value: ProductType.AUTOMOTIVE,
      },
      {
        text: t('productType.furniture'),
        value: ProductType.FURNITURE,
      },
      {
        text: t('productType.hobbyAndArt'),
        value: ProductType.HOBBY,
      },
      {
        text: t('productType.jewellery'),
        value: ProductType.JEWELRY,
      },
      {
        text: t('productType.accessories'),
        value: ProductType.ACCESSORIES,
      },
      {
        text: t('productType.gamesAndConsoles'),
        value: ProductType.GAMING,
      },
      {
        text: t('productType.officeSupplies'),
        value: ProductType.STATIONERY,
      },
      {
        text: t('productType.adult'),
        value: ProductType.ADULT,
      },
      {
        text: t('productType.antiquesAndCollections'),
        value: ProductType.ANTIQUE_COLLECTIBLES,
      },
      {
        text: t('productType.digital'),
        value: ProductType.DIGITAL,
      },
      {
        text: t('productType.cleaningAndHygiene'),
        value: ProductType.HYGIENE,
      },
      {
        text: t('productType.musicAndVideo'),
        value: ProductType.MUSIC_VIDEO,
      },
      {
        text: t('productType.cars'),
        value: ProductType.VEHICLE,
      },
    ],
    'EventType': [],
    'ServiceType': [],
    'CourseType': []
  }), [t]);

  return useMemo(() => {
    const storeFocusEl = storeFocuses[storeFocusObj.type].find(el => el.value === storeFocusObj.enumNumber)
    return storeFocusEl?.text
  }, [storeFocusObj, storeFocuses])
}
