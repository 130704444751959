import { FC, ReactNode, useEffect, useRef } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { useScrollBlock } from 'src/hooks/useScrollBlock';
import ReactPortal from './ReactPortal';
import { modalRules } from './modal.style';

interface IProp {
  children?: ReactNode;
  active: boolean;
  setActive?: any;
  name: ModalNames;
  onClose: () => void;
  propsStyles?: IStyles;
}

interface IStyles {
  paddingTop?: number;
  paddingRight?: number;
  paddingLeft?: number;
  paddingBottom?: number;
}

export enum ModalNames {
  SORT = 'sort',
  FILTERS = 'filters',
  MODIFICATOR = 'modificator',
  RESULT = 'result',
}

const Modal: FC<IProp> = props => {
  const { children, active, name, onClose, propsStyles } = props;
  const [blockScroll, allowScroll] = useScrollBlock();
  const modalRef = useRef<HTMLDivElement>(null);
  const modalContentRef = useRef<HTMLDivElement>(null);
  const modalCloseBarRef = useRef<HTMLDivElement>(null);
  const modalTitleRef = useRef<HTMLParagraphElement>(null);

  const { css } = useStyle(modalRules, {
    active,
    paddingTop: propsStyles?.paddingTop,
    paddingRight: propsStyles?.paddingRight,
    paddingLeft: propsStyles?.paddingLeft,
    paddingBottom: propsStyles?.paddingBottom,
    titleHeight: modalTitleRef.current?.clientHeight,
  });

  useEffect(() => {
    const modalContent = modalContentRef.current;
    const modalCloseBar = modalCloseBarRef.current;
    let startClientY = 0;

    if (modalCloseBar) {
      modalCloseBar.addEventListener('pointerdown', startSwipe);
      modalCloseBar.ondragstart = function () {
        return false;
      };
      // pointercancel
    }

    function startSwipe(event) {
      startClientY = event.clientY;
      let translateY;

      modalCloseBar.setPointerCapture(event.pointerId);

      // Блокируем событие
      if (event.target.closest('.preventDefault')) {
        return;
      }

      function move(event) {
        const { clientY } = event;
        translateY = clientY - startClientY;

        if (translateY < 0) {
          modalContent.style.transform = 'translateY(0px)';
        } else {
          modalContent.style.transform = `translateY(${translateY}px)`;
        }
      }

      function pointerUp() {
        if (translateY > modalContent.clientHeight / 2) {
          modalContent.style.transform = `translateY(${modalContent.clientHeight}px)`;
          setTimeout(onClose, 100);
        } else {
          modalContent.style.transform = 'translateY(0px)';
        }
        modalCloseBar.removeEventListener('pointermove', move);
        modalCloseBar.removeEventListener('pointerup', pointerUp);
      }

      modalCloseBar.addEventListener('pointermove', move);
      modalCloseBar.addEventListener('pointerup', pointerUp);
    }

    return function () {
      if (modalCloseBar) {
        modalCloseBar.removeEventListener('pointerdown', startSwipe);
      }
    };
  }, [modalContentRef, onClose, active]);

  // Закрытие модалки при клике по тёмной области
  useEffect(() => {
    function handleClick(e: Event) {
      if (e.target === modalRef.current) {
        onClose();
      }
    }

    if (active) {
      blockScroll();
      document.addEventListener('click', handleClick);
    }

    return () => {
      document.removeEventListener('click', handleClick);
      allowScroll();
    };
  }, [active, onClose, allowScroll, blockScroll]);

  return (
    <ReactPortal portalID={name}>
      <div className={css.modal} ref={modalRef}>
        <div className={css.modalContent} ref={modalContentRef}>
          <div className={css.closeBar} ref={modalCloseBarRef} />
          {children}
        </div>
      </div>
    </ReactPortal>
  );
};

export default Modal;
