import { memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { useTheme } from 'src/utils/theme/useTheme';
import { Button } from '../UI/Button/Button';
import Text from '../UI/Text/Text';
import { InfoblockRules } from './infoBlock.style';
import { useTelegram } from 'src/utils/telegramProvider';

interface IProps {
  className?: string;
  title: string;
  text: string;
  buttonText: string;
  buttonUrl: string;
  showButton: boolean;
  accentColor: boolean;
}

const InfoBlock = function InfoBlock(props: IProps) {
  const { text, title, buttonText, buttonUrl, showButton, accentColor } = props;
  const { css } = useStyle(InfoblockRules, { accentColor });
  const { theme } = useTheme();
  const { webApp } = useTelegram();

  function handleButtonClick() {
    webApp.openLink(buttonUrl, { try_instant_view: true });
  }

  return (
    <div className={css.wrapper}>
      <Text text={title} fontWeight="700" extend={css.title} mod="title" />
      <div className={css.content}>
        <Text
          text={text}
          fontSize="12px"
          fontWeight="500"
          lineHeight="16px"
          extend={css.text}
          mod="text"
        />
        {showButton && (
          <Button
            text={buttonText}
            propsStyles={{
              width: '100%',
              height: 42,
              background: accentColor ? `rgba(${theme.colorRgbWhite}, 0.2)` : theme.colorBlack,
            }}
            extend={css.button}
            onClick={handleButtonClick}
          />
        )}
      </div>
    </div>
  );
};

export default memo(InfoBlock);
